<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
            <v-card class="mt-7 mx-1 mr-2 elevation-1">
              <v-toolbar flat>
                <v-card-subtitle
                  ><b>Proof of Presence Settings</b>
                </v-card-subtitle>
                <v-switch
                  v-model="proofwork"
                  dense
                  inset
                  class="mt-6 mr-5"
                  :label="`${
                    proofwork.toString() == 'true' ? 'Enable' : 'Disable'
                  }`"
              /></v-toolbar>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n4">
                      <v-icon color="primary" left> mdi-qrcode </v-icon>
                      QR Scan
                      <v-spacer />
                      <v-switch
                        v-model="forms.is_qr"
                        dense
                        :label="`${
                          forms.is_qr.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                        :disabled="proofwork == false"
                        class="mt-5"
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n8">
                      <v-icon color="black" left> mdi-bluetooth-audio </v-icon>
                      Beacon
                      <v-spacer />
                      <v-switch
                        v-model="forms.is_beacon"
                        dense
                        :label="`${
                          forms.is_beacon.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                        :disabled="proofwork == false"
                        class="mt-2"
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                  <v-col cols="12">
                    <v-toolbar flat class="mt-n9">
                      <v-icon color="green" left> mdi-face-recognition </v-icon>
                      Face 
                      <v-spacer />
                      <v-switch
                        v-model="forms.is_face"
                        dense
                        :label="`${
                          forms.is_face.toString() == 'true'
                            ? 'Enable'
                            : 'Disable'
                        }`"
                        inset
                        :disabled="proofwork == false"
                        class="mt-4"
                        @change="faceforms"
                      >
                      </v-switch>
                    </v-toolbar>
                  </v-col>
                  <div v-if="forms.is_face == true">
                    <v-row>
                      <v-col cols="12">
                        <v-toolbar flat class="ml-8 mt-n8">
                          Scan Any Where<br />
                          (Outside the organization’s geofenced locations)

                          <v-spacer />
                          <v-switch
                            v-model="forms.is_face_scan_where"
                            dense
                            :label="`${
                              forms.is_face_scan_where.toString() == 'true'
                                ? 'Enable'
                                : 'Disable'
                            }`"
                            class="mt-5 mr-3"
                            :disabled="proofwork == false"
                            inset
                            @change="scanloc()"
                          >
                          </v-switch>
                        </v-toolbar>
                      </v-col>
                      <v-col cols="12">
                        <v-toolbar flat class="mt-n7 ml-8">
                          All Geo Location
                          <v-spacer />
                          <v-switch
                            v-model="forms.is_face_all_over"
                            dense
                            :label="`${
                              forms.is_face_all_over.toString() == 'true'
                                ? 'Enable'
                                : 'Disable'
                            }`"
                            class="mt-5 mr-3"
                            :disabled="proofwork == false"
                            inset
                            @change="alloc()"
                          >
                          </v-switch>
                        </v-toolbar>
                      </v-col>
                    </v-row>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
            <v-card class="mt-10 mb-5 mx-1 elevation-1">
              <v-row>
                <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="6">
                  <v-row no-gutters>
                    <v-card-title style="font-size: 14px; color: #424242">
                      Reasons for Proof of Work
                    </v-card-title>
                  </v-row>

                  <v-row>
                    <v-col cols="9" sm="9" xs="9" md="9" lg="9" xl="9">
                      <v-form ref="form1">
                        <v-text-field
                          label="Enter Data"
                          dense
                          :rules="rules_scan_anywhere"
                          outlined
                          v-model="addCaptureValue"
                          class="ml-4 mt-2"
                        ></v-text-field>
                      </v-form>
                    </v-col>

                    <v-col cols="1" sm="1" xs="1" md="1" lg="1" xl="1">
                      <v-btn
                        small
                        color="primary"
                        class="white--text mt-3"
                        @click="addData(addCaptureValue)"
                      >
                        <v-icon dark>mdi-plus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
                  <v-row
                    no-gutters
                    class="ml-6"
                    v-for="(item, idx) in DataItems"
                    :key="idx"
                  >
                    <v-col
                      cols="10"
                      sm="7"
                      xs="7"
                      md="7"
                      lg="7"
                      xl="7"
                      class="mt-n10"
                      ><li style="list-style-type: none" class="mt-3">
                        {{ item.name }}
                      </li>
                    </v-col>
                    <v-col cols="2" sm="2" xs="2" md="2" lg="2" xl="2">
                      <v-icon
                        small
                        :disabled="item.index < 3"
                        @click="delete_feature(item)"
                        color="red"
                        class="mt-n13 cursor"
                        >mdi-delete</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-toolbar flat class="mt-2"
          ><v-spacer />
          <v-btn
            small
            class="gradient-bg white--text pa-4"
            :loading="saveloader"
            @click="save_both()"
            ><v-icon class="mr-2">mdi-content-save</v-icon> Save Changes
          </v-btn>
        </v-toolbar>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Snackbar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";
import { GetCurrentUserDetailsnew } from "@/mixins/getCurrentUserDetails.js";
import { API, graphqlOperation } from "aws-amplify";
import {
  proof_of_prezence_settings,
  scan_any_where_response_form_list_or_edit,
} from "@/graphql/mutations.js";
export default {
  components: {
    Overlay,
    Snackbar,
  },
  mixins: [GetCurrentUserDetailsnew],
  data: () => ({
    DataItems: [],
    feedid: "",
    proofwork: false,
    SnackBarComponent: {},

    rules_scan_anywhere: [
      (v) =>
        (typeof v === "string" && v.trim().length !== 0) ||
        " Reason cannot be empty",
      (v) =>
        /^[a-z\sA-Z]{1,30}$/.test(v) ||
        " Reason for scan anywhere cannot contain special characters ",
    ],
    data_id: [],
    overlay: false,
    saveloader: false,
    is_face_scan_where: false,
    is_face_all_over: false,
    is_beacon: false,
    addCaptureValue: [],
    forms: {},
  }),
  created() {
    this.getScanAnyWhereList();
    this.GetCurrentUserDetailsnew();
    this.get_data();
  },
  methods: {
    get_data() {
      if (this.$store.getters.get_org_details[0].forms_enable != null) {
        delete this.$store.getters.get_org_details[0].forms_enable.__typename;
      }
      this.proofwork =
        this.$store.getters.get_org_details[0].is_proof_of_work_enable;
      if (this.$store.getters.get_org_details[0].forms_enable != null) {
        this.forms = this.$store.getters.get_org_details[0].forms_enable;
        if (
          [true, false].includes(this.forms.is_face) &&
          this.forms.is_face_all_over == null
        ) {
          this.forms = Object.assign({
            ...this.$store.getters.get_org_details[0].forms_enable,
            is_face_all_over: this.forms.is_face ? false : false,
            is_face_scan_where: this.forms.is_face ? true : false,
          });
        }
      } else {
        this.forms = {
          is_face: true,
          is_qr: false,
          is_beacon: false,
          is_face_scan_where: true,
          is_face_all_over: false,
        };
      }
    },
    faceforms() {
      if (this.forms.is_face == true) {
        this.forms.is_face_scan_where = true;
      }
    },
    alloc() {
      if (this.forms.is_face_all_over == true) {
        this.forms.is_face_scan_where = true;
      } else if (this.forms.is_face_all_over == false) {
        this.forms.is_face_scan_where = true;
      }
    },
    scanloc() {
      if (
        this.forms.is_face_scan_where == false &&
        this.forms.is_face_all_over == false
      ) {
        this.forms.is_face = false;
      }
    },
    save_both() {
      this.updateScanAnyWhereList();
      this.proof_form();

      this.$refs.form1.resetValidation();
    },
    async proof_form() {
      this.forms = Object.assign({
        ...this.forms,
        is_face_all_over: this.forms.is_face
          ? this.forms.is_face_all_over
          : false,
        is_face_scan_where: this.forms.is_face
          ? this.forms.is_face_scan_where
          : false,
      });
      try {
        await API.graphql(
          graphqlOperation(proof_of_prezence_settings, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              forms_enable: this.forms,
              is_proof_of_work_enable: this.proofwork,
            },
          })
        );
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Updated successfully",
        };
        this.overlay = false;
        this.isLoading = false;

        this.GetCurrentUserDetailsnew();
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbar/showMessage", {
          content: error.errors[0].message,
          color: "red",
        });
        this.isLoading = false;
      }
    },
    addData(addCaptureValue) {
      if (this.$refs.form1.validate()) {
        var key_object = {
          name: addCaptureValue,
        };
        var compare = this.DataItems.some(
          (obj) => obj.name === addCaptureValue
        );
        if (compare == false) {
          this.DataItems.push(key_object);
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Reason for Scan Anywhere aldery exist",
          };
        }
        this.$refs.form1.resetValidation();
        this.addCaptureValue = "";
      }
    },

    delete_feature(item) {
      for (let i = 0; i < this.DataItems.length; i++) {
        if (item.name == this.DataItems[i].name) {
          this.DataItems.splice(i, 1);
        }
      }
    },

    async getScanAnyWhereList() {
      this.overlay = true;
      try {
        var inputParams = {
          action_type: "LIST",
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
        };
        let result = await API.graphql(
          graphqlOperation(scan_any_where_response_form_list_or_edit, {
            input: inputParams,
          })
        );
        this.overlay = false;
        this.DataItems = JSON.parse(
          result.data.scan_any_where_response_form_list_or_edit
        ).Items[0].response_message.map((val, i) => {
          return {
            name: val,
            index: i,
          };
        });
        this.feedid = JSON.parse(
          result.data.scan_any_where_response_form_list_or_edit
        ).Items[0].feed_id;
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
      }
    },
    async updateScanAnyWhereList() {
      this.saveloader = true;
      try {
        var inputParams = {
          action_type: "EDIT",
          feed_id: this.feedid,
          response_message: this.DataItems.map((val) => {
            return val.name;
          }),
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
        };
        await API.graphql(
          graphqlOperation(scan_any_where_response_form_list_or_edit, {
            input: inputParams,
          })
        );
        this.getScanAnyWhereList();
        this.$refs.form.reset();
        this.saveloader = false;
      } catch (err) {
        this.saveloader = false;
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
      }
    },
  },
};
</script>

<style>
</style>